<template>
  <transition
      name="bounce"
      appear>
    <div
        class="btn-container"
        :style="{
          top:  `calc(${touchpoint.y}% - 1.5rem)`,
          left: `calc(${touchpoint.x}% - 1.5rem)`
        }">
      <div
          class="btn"
          ref="btn">
        <svg
            v-if="!touchpoint.order_id"
            viewBox="0 0 512 512">
          <polygon points="448,224 288,224 288,64 224,64 224,224 64,224 64,288 224,288 224,448 288,448 288,288 448,288 "/>
        </svg>
        <span
            v-if="touchpoint.order_id"
            class="order-id"
            v-text="touchpoint.order_id" />
        <span
            class="title"
            ref="title"
            v-html="touchpoint.title" />
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  name: 'Touchpoint',

  props: {
    touchpoint: Object
  }
}
</script>


<style scoped lang="scss">
.btn-container {
  position: absolute;
  height: 3rem;
  width: 3rem;
  z-index: 100;

  &:hover {
    z-index: 300;
  }
}

.btn {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffb413;
  top: 0%;
  left: 0%;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &::before,
  &::after {
    content:'';
    display:block;
    position:absolute;
    top:0; right:0; bottom:0; left:0;
    border-radius:50%;
    border: 0.175vh solid #ffb413;
    pointer-events: none;
    backface-visibility: hidden;
    perspective: 1000;
  }

  &::before {
    animation: ripple 2s linear infinite;
  }

  &::after {
    animation: ripple 2s linear 1s infinite;
  }

  &:hover {
    background-color: #5b6e7f;
    transform: scale(1.15);

    &::before,
    &::after {
      border: none;
      animation: none;
    }

    svg {
      polygon {
        fill: #fff;
      }
    }

    .order-id {
      color: #fff;
    }

    .title {
      opacity: 1;
    }
  }

  svg {
    position: absolute;
    width: 60%;
    height: 60%;
    transition: transform 250ms ease-in-out !important;

    polygon {
      fill: #333;
    }
  }

  .order-id {
    font-size: 1.5rem;
    padding-top: 0.25rem;
    vertical-align: text-bottom;
    font-weight: 700;
    color: #000;
    transition: all 150ms ease-in-out;
    pointer-events: none;
  }

  .title {
    position: absolute;
    background-color: #5b6e7f;
    color: #fff;
    font-size: 1.1rem;
    white-space: nowrap;
    padding: 0.8rem 1rem 0.6rem 1rem;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 150%);
    opacity: 0;
    transition: opacity 150ms ease-in-out, box-shadow 500ms ease-in-out;
    pointer-events: none;
    font-weight: 600;
  }
}

@keyframes ripple{
  0% {
    transform: scale3D(1, 1, 1);
  }
  75% {
    transform: scale3D(1.75, 1.75, 1.75);
    opacity: 1;
  }
  100% {
    transform: scale3D(2, 2, 2);
    opacity: 0;
  }
}
</style>
